.wrapper-preview {
    background: #dfd6c5;
    position: sticky;
    top: 16px;
}
.menu-display {
    background: #dfd6c5;
    overflow: auto;
    max-width: 100%;
    border-radius: 8px;
    padding: 16px 0 0 8px;
    display: flex;
    grid-gap: 16px;
    gap: 8px;
    .active {
        font-weight: 700;
    }
    div {
        cursor: pointer;
    }
    > div:last-child {
        margin-right: 8px;
    }
    img {
        min-width: 10rem;
        height: 7rem;
        object-fit: cover;
        border-radius: 0.8rem;
    }
    h4 {
        font-size: 14px;
        padding-top: 8px;
        font-weight: normal;
        text-align: center;
    }
    .active {
        font-weight: 700 !important;
        h4 {
            font-weight: 700 !important;
        }
    }
}

.menu-item {
    background: #dfd6c5;
    overflow: auto;
    max-width: 100%;
    border-radius: 8px;
    padding: 16px 0 16px 8px;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    padding: 10px;
    max-height: 600px;
    overflow: auto;
    img {
        object-fit: cover;
        border-radius: 0.8rem;
        height: 164.75px;
    }
    h4 {
        font-size: 14px;
        padding-top: 8px;
        font-weight: normal;
        text-align: center;
        color: #1a2140;
    }
    .money {
        border: 1px solid #1a2140;
        border-radius: 5px;
        text-align: center;
        color: #1a2140;
    }
}

#display-config-page {
    .rst__tree {
        background: white;
        border-radius: 8px;
        min-height: 80vh;
        padding-top: 16px;
        padding-left: 16px;
    }
    .rst__row {
        height: 40px;
    }
    .rst__moveHandle,
    .rst__loadingHandle {
        border: 0px;
        border-radius: 40px;
        width: 40px;
        background: #1a22412b
            url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0MiIgaGVpZ2h0PSI0MiI+PGcgc3Ryb2tlPSIjRkZGIiBzdHJva2Utd2lkdGg9IjIuOSIgPjxwYXRoIGQ9Ik0xNCAxNS43aDE0LjQiLz48cGF0aCBkPSJNMTQgMjEuNGgxNC40Ii8+PHBhdGggZD0iTTE0IDI3LjFoMTQuNCIvPjwvZz4KPC9zdmc+)
            no-repeat center;
    }
    .rst__rowContents {
        border: 0px solid #1a224196;
        margin-left: 8px;
        border-radius: 8px;
        box-shadow: 2px 0px 9px #96969669;
    }
    .rst__rowTitle {
        color: #1a2241;
        font-size: 14px;
    }

    .preview-box {
        width: 40%;
        max-width: 40%;
        height: min-content;
        position: sticky;
        top: 24px;
        .preview-item {
            &__children {
                background: #dfd6c5;
                padding: 16px 0 6px;
                border-radius: 8px;
                display: flex;
                overflow: auto;
                max-width: 100%;
                gap: 16px;
                padding-bottom: 16px;
                padding-left: 8px;
                img {
                    min-width: 10rem;
                    height: 7rem;
                    object-fit: cover;
                    border-radius: 0.8rem;
                }
                h4 {
                    font-size: 14px;
                    padding-top: 8px;
                    font-weight: normal;
                    text-align: center;
                }
            }
        }
    }
    .cate-box {
        width: 20%;
        min-width: 20%;
        .cate-item {
            border: 0px solid #1a224196;

            border-radius: 8px;
            box-shadow: 2px 0px 9px #96969669;
            padding: 4px 8px;
            margin-bottom: 8px;
            background: white;
        }
    }
}
